.GeneralDataCard {
  margin-top: 80px;
  position: relative; }
  .GeneralDataCard .Tabs {
    position: absolute;
    top: -46px;
    width: 100%;
    z-index: 10; }
  .GeneralDataCard .spacer {
    width: 100%;
    height: 80px; }
