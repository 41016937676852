.ListFiles .color-red {
  color: #cb1b22; }

.ListFiles .color-yellow {
  color: #fbc02d; }

.ListFiles .color-green {
  color: #0d773a; }

.ListFiles .color-blue {
  color: #1556b2; }

.ListFiles .color-orange {
  color: #d45333; }

.ListFiles .button-download {
  background: none;
  padding: 5px;
  border: 2px dashed #b7b7b7;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
  min-width: 150px; }

.ListFiles .button-download:hover {
    border-color: #fbc02d;
    background-color: #ffffe9; }

.ListFiles .button-download:focus, .ListFiles .button-download:active, .ListFiles .button-download:visited {
    outline: none;
    all: none; }

.ListFiles .button-preview {
  margin-top: 3px; }

.ListFiles .list-documents {
  margin: 0px !important;
  padding: 5px 0px 5px 5px !important; }

.ListFiles .file-name {
  width: 230px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.ListFiles .download {
  font-weight: bold;
  color: #8e8888; }

.Preview {
  width: 100%;
  height: 100%; }

.Preview .pdfIFrame {
    width: 90vw;
    height: 80vh; }

.Preview .imgIFrame {
    max-height: 80vh;
    max-width: 90vw;
    display: block;
    margin: auto; }
