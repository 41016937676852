.StatusAccount .topHeader {
  height: 0.5rem;
  width: 100%;
  background-color: #4eb59e; }

.StatusAccount .buttonDetails {
  color: #ffff00; }

.StatusAccount .header-container {
  background-color: #4eb59e; }

.StatusAccount .header-container h4 {
    color: #fafafa;
    padding: 0.1rem;
    font-size: 0.9rem; }
