.ModuleContainer .header {
  height: 10rem;
  margin: 0; }
  .ModuleContainer .header h1 {
    margin: 0;
    padding-top: 1.5rem;
    text-align: center;
    font-size: 1.5rem; }
  .ModuleContainer .main {
  margin-top: -3.5rem; }
  .ModuleContainer .main .container {
    width: 100%;
    min-height: 35rem; }
  .ModuleContainer .jss164 {
  width: 100% !important; }
  .ModuleContainer svg {
  margin: auto 0.5rem; }
