.Header {
  -webkit-box-flex: 1;
          flex-grow: 1;
  -webkit-box-pack: center;
          justify-content: center; }
  .Header h4 {
    margin: auto 4%; }
  .Header .MenuIcon {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
