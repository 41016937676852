.WithdrawalWorker {
  width: 100%; }
  .WithdrawalWorker .validation:not(:focus):not(:placeholder-shown):invalid {
    border: 2px solid #f50057;
    border-radius: 4px; }
  .WithdrawalWorker .topHeader {
    height: 0.5rem;
    width: 100%;
    background-color: #2b977f;
    margin-bottom: 1rem; }
  .WithdrawalWorker .buttonDetails {
    color: #ffff00; }
  .WithdrawalWorker .header-container {
    background-color: #2b977f; }
  .WithdrawalWorker .header-container h4 {
      color: #fafafa;
      padding: 0.1rem;
      font-size: 0.9rem; }
  .WithdrawalWorker .btn-green {
    border-color: #0c9e42;
    color: #0c9e42;
    height: 100%;
    width: 100%; }
  .WithdrawalWorker .btn-green:hover {
    color: #0c9e42;
    border-color: #0c9e42;
    background-color: rgba(101,255,222,0.19216); }
  .WithdrawalWorker .formControl {
    min-width: 120;
    height: 100%;
    width: 100%; }
  .WithdrawalWorker .selectEmpty {
    margin-top: 1rem; }
  .WithdrawalWorker hr {
    border: 1px solid;
    margin: 1.5rem 0 1rem 0; }
  .WithdrawalWorker .submitButton {
    background-color: #0c9e42 !important;
    color: white;
    padding: 1rem;
    font-weight: 700; }
  .WithdrawalWorker .submitButton:hover {
    background-color: #09642b !important;
    color: white;
    padding: 1rem;
    font-weight: 700; }
  .WithdrawalWorker .MuiPickersToolbar-toolbar {
    background-color: #0c9e42 !important; }
  .WithdrawalWorker .MuiFormLabel-asterisk {
    color: red; }
