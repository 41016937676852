.EmployerRegistrationWithdrawal {
  width: 100%; }
  .EmployerRegistrationWithdrawal .topHeader {
    height: 0.5rem;
    width: 100%;
    background-color: #2b977f;
    margin-bottom: 1rem; }
  .EmployerRegistrationWithdrawal .buttonDetails {
    color: #ffff00; }
  .EmployerRegistrationWithdrawal .header-container {
    background-color: #2b977f; }
  .EmployerRegistrationWithdrawal .header-container h4 {
      color: #fafafa;
      padding: 0.1rem;
      font-size: 0.9rem; }
  .EmployerRegistrationWithdrawal .btn-green {
    border-color: #0c9e42;
    color: #0c9e42;
    height: 100%;
    width: 100%; }
  .EmployerRegistrationWithdrawal .btn-green:hover {
    color: #0c9e42;
    border-color: #0c9e42;
    background-color: rgba(101,255,222,0.19216); }
  .EmployerRegistrationWithdrawal .formControl {
    min-width: 120;
    height: 100%;
    width: 100%; }
  .EmployerRegistrationWithdrawal .selectEmpty {
    margin-top: 1rem; }
  .EmployerRegistrationWithdrawal hr {
    border: 1px solid;
    margin: 1.5rem 0 1rem 0; }
  .EmployerRegistrationWithdrawal .submitButton {
    background-color: #0c9e42 !important;
    color: white;
    padding: 1rem;
    font-weight: 700; }
  .EmployerRegistrationWithdrawal .submitButton:hover {
    background-color: #09642b !important;
    color: white;
    padding: 1rem;
    font-weight: 700; }
  .EmployerRegistrationWithdrawal .MuiPickersToolbar-toolbar {
    background-color: #0c9e42 !important; }
  .EmployerRegistrationWithdrawal .MuiFormLabel-asterisk {
    color: red; }
