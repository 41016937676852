.SignIn {
  margin: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  padding: 10% 0; }
  .SignIn .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column; }
  .SignIn .background .upperColor {
      height: 60%;
      width: 100%;
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 69%, 0 100%);
              clip-path: polygon(0 0, 100% 0, 100% 69%, 0 100%); }
  .SignIn .background .lowerColor {
      position: absolute;
      top: 35vh;
      width: 100%;
      height: 80vh;
      -webkit-clip-path: polygon(0 12%, 100% 37%, 100% 100%, 0 100%);
              clip-path: polygon(0 12%, 100% 37%, 100% 100%, 0 100%); }
  .SignIn .captionText {
    margin: 1rem 0;
    text-align: center; }
