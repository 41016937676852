.NewSalary .validation:not(:focus):not(:placeholder-shown):invalid {
  border: 2px solid #f50057;
  border-radius: 4px; }

.NewSalary .topHeader {
  height: 0.5rem;
  width: 100%;
  background-color: #2b977f;
  margin-bottom: 1rem; }

.NewSalary .buttonDetails {
  color: #ffff00; }

.NewSalary .header-container {
  background-color: #2b977f; }

.NewSalary .header-container h4 {
    color: #fafafa;
    padding: 0.1rem;
    font-size: 0.9rem; }

.NewSalary .btn-green {
  border-color: #0c9e42;
  color: #0c9e42;
  height: 100%;
  width: 100%; }

.NewSalary .btn-green:hover {
  color: #0c9e42;
  border-color: #0c9e42;
  background-color: rgba(101,255,222,0.19216); }

.NewSalary .formControl {
  min-width: 120;
  height: 100%;
  width: 100%; }

.NewSalary .selectEmpty {
  margin-top: 1rem; }

.NewSalary hr {
  border: 1px solid;
  margin: 1.5rem 0 1rem 0; }

.NewSalary .submitButton {
  background-color: #0c9e42 !important;
  color: white;
  padding: 1rem;
  font-weight: 700; }

.NewSalary .submitButton:hover {
  background-color: #09642b !important;
  color: white;
  padding: 1rem;
  font-weight: 700; }

.NewSalary .MuiPickersToolbar-toolbar {
  background-color: #0c9e42 !important; }

.NewSalary .MuiFormLabel-asterisk {
  color: red; }
