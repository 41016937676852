.WorkersDetails {
  padding-top: 1rem; }
  .WorkersDetails .title {
    max-width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    padding: 5%; }
  .WorkersDetails .title svg {
      margin: 3% 5%;
      -webkit-transform: scale(1.6);
              transform: scale(1.6); }
  .WorkersDetails .title h4 {
      font-size: 2em; }
  .WorkersDetails .workers {
    background-color: #2b977f;
    text-align: center; }
  .WorkersDetails .workersNumber {
    color: #f3f0f0; }
  .WorkersDetails .WorkersDetailsLoading {
    margin: 10%;
    text-align: center; }
