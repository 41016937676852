.CardDocuments {
  padding: 1rem !important; }
  .CardDocuments .card .cursor-pointer {
    display: -webkit-box !important;
    display: flex !important;
    padding: 0.5rem;
    cursor: pointer; }
  .CardDocuments .card .icon-size {
    font-size: 2rem !important; }
  .CardDocuments .card .svg-inline--fa {
    font-size: 2rem !important; }
  .CardDocuments .card p {
    font-family: "Titillium Web", sans-serif !important; }
  .CardDocuments .card .iconContainer {
    margin: auto 1rem !important; }
