.Requests .topHeader {
  height: 0.5rem;
  width: 100%;
  background-color: #2b977f; }

.Requests .header-container {
  background-color: #2b977f; }

.Requests .header-container h4 {
    color: #fafafa;
    padding: 0.1rem;
    font-size: 0.9rem; }

.Requests h2 {
  padding: 0 1rem 0 1rem;
  text-align: center; }

.Requests .extendedFab {
  position: fixed;
  top: 1rem;
  right: 1rem;
  fabtext-font-weight: bold; }

.Requests .extendedIcon {
  margin-right: 1rem; }

.Requests .requestButton {
  text-align: center; }

.Requests .pending {
  color: #ff0077; }

.Requests .pending-back {
  color: #ffcae3; }

.Requests .in_revision {
  color: #2846cc; }

.Requests .in_revision-back {
  color: #c9d3ff; }

.Requests .imss_revision {
  color: #610f97; }

.Requests .imss_revision-back {
  color: #f1dbff; }

.Requests .approved {
  color: #0ba72d; }

.Requests .approved-back {
  color: #c9ffd4; }

.Requests .finished {
  color: #f38e16; }

.Requests .finished-back {
  color: #ffe5b8; }

.Requests .canceled {
  color: #7e0606; }

.Requests .canceled-back {
  color: #ffc0c0; }

.Requests .progressCircleLabel {
  font-size: 0.7rem; }

.Requests .MuiCircularProgress-svg {
  margin: 0px; }

.Requests .fullWidth {
  width: 100%; }
