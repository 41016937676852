.loadfile {
  box-sizing: border-box;
  overflow-x: hidden;
  height: 100vh; }
  .loadfile .dropzone {
    margin: 1rem 0;
    padding: 2rem 4rem;
    width: 100%;
    background-color: #fafafa;
    color: #bdbdbd;
    -webkit-box-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: center;
            align-items: center;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    outline: none;
    -webkit-transition: border .24s ease-in-out;
    transition: border .24s ease-in-out; }
  .loadfile .paper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    padding: 10px; }
  .loadfile .file-item {
    padding: .3rem; }
  .loadfile .MuiInputLabel-formControl {
    position: static;
    position: initial; }
  .loadfile .buttonDeleteFile {
    margin: 0 0.5rem; }
  .loadfile .buttonDeleteFile .files-icon {
      font-size: 1rem !important;
      color: rgba(171,166,166,0.87059); }
  .loadfile ul {
    padding: 0; }
