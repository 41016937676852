.ChangeAccount .title {
  max-width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding: 5%; }
  .ChangeAccount .title svg {
    margin: 3% 5%;
    -webkit-transform: scale(1.6);
            transform: scale(1.6); }
  .ChangeAccount .title h4 {
    font-size: 2em; }
  .ChangeAccount .AccountsList {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center; }
  .ChangeAccount .AccountsList > button {
    width: 90%; }
  .ChangeAccount .ChangeAccountLoading {
  margin: 10%;
  text-align: center; }
