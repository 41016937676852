.BranchOfficeData .Card {
  width: 100%;
  box-shadow: none !important;
  border: none;
  margin: 1rem; }

.BranchOfficeData > * {
  text-transform: none;
  text-align: left; }

.BranchOfficeData .content {
  -webkit-box-flex: 1;
          flex: 1 0 auto;
  min-height: 100px; }

.BranchOfficeData .cover {
  width: 151; }

.BranchOfficeData .modalEmployees {
  width: 100%;
  height: 100%; }
