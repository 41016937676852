.DisplayCustomerData {
  padding: 5%; }
  .DisplayCustomerData .DisplayMenu {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    height: 100%; }
  .DisplayCustomerData .DisplayMenu button {
      width: 100%;
      text-transform: none;
      font-weight: bold; }
  .DisplayCustomerData .DisplayMenu button * {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
                justify-content: center;
        -webkit-box-align: center;
                align-items: center; }
