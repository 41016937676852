.SignInForm .formContainer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  margin: 5% 10%; }
  .SignInForm .formContainer .SignInInput {
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin: 13% 0; }
  .SignInForm .formContainer .SignInInput .ErrorMessage {
      margin: 2%; }
  .SignInForm .formContainer button {
    margin: 7% 0; }
  .SignInForm .formContainer button svg {
      margin: 2%; }
  .SignInForm .formContainer .CircularLoading {
    margin: 10%;
    align-self: center; }
