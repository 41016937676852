.CardDirectory {
  width: 90%;
  padding: 0.5rem; }
  .CardDirectory h3 {
    font-size: 1.5rem;
    margin: 0.6rem; }
  .CardDirectory h6 {
    font-size: 1.1em;
    margin: 0.6rem; }
  .CardDirectory .papper {
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px; }
  .CardDirectory svg {
    margin: 0 0.6rem; }
  .CardDirectory .cursor-pointer {
    cursor: pointer; }
