.MobileModal .MuiDialog-paperFullScreen {
  background: #e6e9ef; }

.MobileModal .DialogTitleContainer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center; }

.MobileModal .dialogContent {
  margin: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute; }

.MobileModal .dialogContent .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw; }

.MobileModal .dialogContent .background .upperColor {
      height: 60%;
      width: 100%;
      background-color: #3175d6; }

.MobileModal .dialogContent .background .lowerColor {
      position: absolute;
      top: 40vh;
      width: 100%;
      height: 70vh;
      background-color: #e6e9ef; }

.MobileModal .dialogContent .headerContainer {
    display: -webkit-box;
    display: flex;
    position: absolute;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: center;
            justify-content: center;
    width: 100%;
    top: 5vh; }

.MobileModal .dialogContent .headerContainer svg {
      margin: 0 0.5rem; }

.MobileModal .dialogContent .container {
    width: 100%;
    top: 20vh;
    min-height: 50vh;
    display: -webkit-box;
    display: flex;
    position: absolute;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: center;
            justify-content: center; }

.MobileModal .dialogContent .container .childrenContainer {
      height: 100%; }
