.HeroCard .container {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  min-height: 200px; }
  .HeroCard .container .AvatarContainer {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    width: 30%; }
  .HeroCard .container .DisplayDataContainer {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    width: 70%;
    padding-right: 5%; }
  .HeroCard .container .DisplayDataContainer .TextFields input {
      font-size: 0.9em; }
  .HeroCard .container .DisplayDataContainer .DataControllers {
      display: -webkit-box;
      display: flex;
      flex-wrap: nowrap;
      -webkit-box-pack: center;
              justify-content: center;
      -webkit-box-align: center;
              align-items: center;
      width: 100%;
      margin: 5% auto; }
  .HeroCard .container .DisplayDataContainer .DataControllers label {
        margin: 0 2%;
        height: 100%; }
  .HeroCard .container .DisplayDataContainer .DataControllers label button {
          padding: 1%;
          width: 100%;
          height: 100%; }
  .HeroCard .container .DisplayDataContainer .DataControllers button {
        margin: 0 2%; }
  .HeroCard .container .DisplayDataContainer .DataControllers svg {
        -webkit-transform: scale(1.5);
                transform: scale(1.5); }
